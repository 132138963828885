<template>
    <div class="border-box" v-if="time.year_name>0">
                <div class="border-box-body">
                    <!---->
                    <div class="body-hd-2">{{ time.lotto_name }} {{ time.year_name }}年 第{{ time.journal_name }}期</div>
                    <div class="border-box-body-bd">
                        <div class="van-row">
          
                            <div class="van-col van-col--3" v-for="(item,index) in list" :key="item.id">
                               
                                <div class="connect" v-if="index==6"><i class="van-icon van-icon-plus" style="color: rgb(172, 172, 172);"></i></div>

                                <div class="icon-text" v-else>
                                
                                    <div><span :class="item.bose_name=='绿波' ? 'slot-icon bg-amage-3' : item.bose_name=='红波' ? 'slot-icon bg-amage-1' : item.bose_name=='蓝波' ? 'slot-icon bg-amage-2':'slot-icon'"><span>{{ item.haoma_name }}</span></span></div>
                                    <div class="slot-text">
                                        {{ item.animal_name }}<span>/{{ item.five_name }}</span></div>
                                    
                                </div>
                            </div>


                        </div>
                    </div>
                    <!---->
                </div>
            </div>

</template>

<script>
export default {
  props: {
    year_id:Number,
    lotto_id:Number,
    journal_id:Number,
  },
  watch:{
    //监听props 值变化
    'year_id'(){
        this.getData();
    },
    'lotto_id'(){
        this.getData();
    },
    'journal_id'(){
        this.getData();
    },
  },
  data() {
    return {
      active: 'picture',
      time:[
        {year_name:0}
      ],
      list:[],
      data:[],
      lotto_list:[],
      journal_list:[],
      year_list:[],
      loading: false,
      finished: false,
      iserror:false,
      page:1,
      message:'',
      iscolour: 0,
      option1: [],
    };
  },
  mounted() {
       this.getData();
    },
    methods: {
        onClickLeft() {
            // 返回上一级页面
            this.$router.back();
        },
        getData() {

            this.$http
                .get("api/kaiJiang/getresult?year_id="+this.year_id+"&lotto_id="+this.lotto_id+"&journal_id="+this.journal_id)
                .then(function (response) {
                   
                    if (response.data.code == "ok") {
                        this.list= response.data.data.list;
                        this.list.splice(6,0,{});
                        this.time= response.data.data.time;
                        this.loading = false;
                    } else {
                        this.list= response.data.data.list;
                        this.time= response.data.data.time;
                        this.iserror = true;
                        this.message = response.data.msg;
                    }
       
                    this.loading = false;
                    //console.log(this.list);
                });
    
        },

    },
}
</script>


<style scoped>
.sf{
    display: none;
}
</style>
